import React from "react";
import { Button, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

interface MenuItem {
  key: string;
  icon: JSX.Element;
  buttonClassName: string;
  title: string;
  path: string;
}

interface CollapsibleMenuProps {
  collapsed: boolean;
  items: MenuItem[];
  handleToggleCollapse: () => void;
  navigate: (path: string) => void;
  className?: string; // Definindo a propriedade className como opcional
}

const CollapsibleMenu: React.FC<CollapsibleMenuProps> = ({
  collapsed,
  items,
  handleToggleCollapse,
  navigate,
  className,
}) => {
  const selectedKeys = items
    .filter((item) => window.location.pathname.startsWith(item.path))
    .map((item) => item.key);

  return (
    <div
      className={`flex-col gap-8 pt-5 items-start px-3 border-r ${className}`}
      style={{ minHeight: "100vh" }}
    >
      <Button onClick={handleToggleCollapse} style={{ marginBottom: 16 }}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      <Menu
        defaultSelectedKeys={["1"]}
        selectedKeys={selectedKeys}
        mode="inline"
        theme="light"
        inlineCollapsed={!collapsed}
        style={{
          width: collapsed ? "120px" : "200px",
          border: "none !important",
          borderColor: "none !important",
        }}
      >
        {items.map((menuItem) => (
          <Menu.Item
            key={menuItem.key}
            style={{
              height: "60px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingLeft: "16px",
            }}
            title={null}
          >
            <Button
              icon={menuItem.icon}
              shape="circle"
              // color="#fff"
              className={menuItem.buttonClassName}
              style={{
                width: "48px",
                height: "48px",
                fontSize: "0",
              }}
              onClick={() => navigate(menuItem.path)}
            />
            {!collapsed && (
              <span
                onClick={() => navigate(menuItem.path)}
                style={{ marginLeft: "8px" }}
              >
                {menuItem.title}
              </span>
            )}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

export default CollapsibleMenu;
